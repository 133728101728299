import * as React from "react";
import styled from "styled-components";
import { ScreenSizes } from "../shared-code";

const Wrapper = styled.div<{ hide: boolean }>`
  visibility: ${(props) => (props.hide ? "hidden" : "visible")};
  width: 100%;
  @media (max-width: ${ScreenSizes.narrow}) {
    padding-block: 20px;
  }
`;

const TitleText = styled.div`
  padding-block: 20px;
  color: white;
  font-size: 30px;
  font-weight: 600;
  text-align: left;
  width: 100%;

  @media (max-width: ${ScreenSizes.narrow}) {
    text-align: center;
    padding-block: 10px;
  }
`;

interface ContentBlockProps {
  id?: string | undefined;
  title: string;
  children: React.ReactNode;
  hide?: boolean | undefined;
}

export const ContentBlock = (props: ContentBlockProps) => {
  const hide = props.hide != undefined ? props.hide : false;
  return (
    <Wrapper id={props.id} hide={hide}>
      <TitleText>{props.title}</TitleText>
      {props.children}
    </Wrapper>
  );
};
